<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading"/>

    <Hero
    :isLoading="isLoading"
    :title="postTitle"
    :subtitle="postDescription"
    :image="postFeaturedImage"/>

    <div class="cus-single cus-content content">
      <div v-show="!isLoading" v-html="contentHtml"></div>

      <div class="section">
        <div class="container">
          <router-link
            custom
            v-slot="{ navigate }"
            :to="{ name: 'Archive', params: { postType: $route.params.postType } }">
            <b-button type="is-primary" icon-pack="far" icon-left="arrow-left" @click="navigate" @keypress.enter="navigate" role="link">
              Terug naar {{ $route.params.postType === 'participaties' ? 'meedoen' : $route.params.postType }}
            </b-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import router from '@/router'

import Hero from '@/components/protected/Hero'
import Section from '@/components/protected/blocks/Section'
import LatestPostsCards from '@/components/protected/blocks/LatestPostsCards'
import ProjectFases from '@/components/protected/blocks/ProjectFases'
import Map from '@/components/protected/blocks/Map'
import Form from '@/components/protected/blocks/Form'
import Slider from '@/components/protected/blocks/Slider'

export default {
  name: 'Single',

  components: {
    Hero,
  },

  data() {
    return {
      isLoading: true,
      blocks: [],
      contentHtml: null
    }
  },

  computed: {
    postType() {
      return this.$route.params.postType
    },

    postSlug() {
      return this.$route.params.slug
    },

    postTitle() {
      if (this.$store.getters['wp/post/hasPost']) {
        return this.$store.getters['wp/post/post'].title.rendered
      }
      return ''
    },

    postDescription() {
      if (this.$store.getters['wp/post/hasPost']) {
        return this.$store.getters['wp/post/post'].advanced_custom_fields.description
      }
      return ''
    },

    postFeaturedImage() {
      if (this.$store.getters['wp/post/hasPost']) {
        return this.$store.getters['wp/post/post'].featured_media_links.original
      }
      return ''
    },

    postContent() {
      if (this.$store.getters['wp/post/hasPost']) {
        return this.$store.getters['wp/post/post'].content.rendered
      }
      return ''
    },
  },

  watch: {
    '$route'() {
      this.init()
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      await this.$store.dispatch('wp/post/getPost', {
        type: this.postType,
        slug: this.postSlug
      })
      if (this.$store.getters['wp/post/hasPost']) {
        this.contentHtml = this.postContent
        setTimeout(async () => {
          await this.renderBlocksInContent()
          await this.renderContactForms()

          this.isLoading = false
        }, 100)
      } else {
        this.$router.push({ name: 'NotFound' })
      }
    },

    renderBlocksInContent(content = document) {
      let renderBlocks = content.querySelectorAll(`[data-render-block]`)
      console.log(renderBlocks)

      while (renderBlocks.length) {
        let renderType = renderBlocks[0].getAttribute('data-render-type')
        let renderProps = renderBlocks[0].getAttribute('data-render-props')

        let ComponentClass = null
        if (renderType == 'Section') {
          ComponentClass = Vue.extend(Section)
        } else if (renderType == 'LatestPostsCards') {
          ComponentClass = Vue.extend(LatestPostsCards)
        } else if (renderType == 'Map') {
          ComponentClass = Vue.extend(Map)
        } else if (renderType == 'ProjectFases') {
          ComponentClass = Vue.extend(ProjectFases)
        } else if (renderType == 'Form') {
          ComponentClass = Vue.extend(Form)
        } else if (renderType == 'Slider') {
          ComponentClass = Vue.extend(Slider)
        }

        let componentInstance = new ComponentClass({
          store,
          router,
          propsData: {
            ...JSON.parse(renderProps),
            childContent: renderBlocks[0].innerHTML
          }
        })

        componentInstance.$mount()
        renderBlocks[0].replaceWith(componentInstance.$el)

        renderBlocks = content.querySelectorAll(`[data-render-block]`)
      }
    },

    renderContactForms() {
      let contactForms = document.querySelectorAll('form.wpcf7-form')

      while (contactForms.length) {
        const formId = contactForms[0].querySelectorAll('input[name="_wpcf7"]')[0].value
        let ComponentClass = Vue.extend(Form)
        let componentInstance = new ComponentClass({
          store,
          router,
          propsData: {
            formId: formId,
            childContent: contactForms[0].innerHTML
          }
        })

        componentInstance.$mount()
        contactForms[0].replaceWith(componentInstance.$el)
        contactForms = document.querySelectorAll('.wp-block-contact-form-7-contact-form-selector')
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/views/protected/Page.scss"></style>
