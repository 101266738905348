<template>
  <div>
    <div v-show="hasActiveFilters" class="card mb-5">
      <div class="card-header">
        <h1 class="card-header-title is-size-5">Actieve filters</h1>
      </div>
      <div class="card-content">
        <b-field grouped group-multiline>
          <div class="control" v-for="category in selectedCategories" :key="category.id">
            <b-tag type="is-primary" closable @close="removeCategoryFilter(category)">
              {{ category.name }}
            </b-tag>
          </div>
        </b-field>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h1 class="card-header-title is-size-5">Categorieën</h1>
      </div>
      <div class="panel-block">
        <p class="control has-icons-left">
          <b-input
          v-model="searchCategory"
          placeholder="Zoek categorie..."
          type="search"
          icon-pack="far"
          icon="search"/>
        </p>
      </div>
      <span class="panel-block" v-for="category in categories" :key="category.id">
        <b-checkbox v-model="selectedCategories" :native-value="category">{{ category.name }}</b-checkbox>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News-Sidebar-Component',

  props: {
    categoryFilters: Array
  },

  computed: {
    hasActiveFilters() {
      if (this.selectedCategories.length) {
        return true
      }
      return false
    },

    categories() {
      const self = this
      return this.$store.getters['wp/archive/categories'].filter((category) => {
        return category.name.toLowerCase().includes(self.searchCategory.toLowerCase())
      })
    }
  },

  data() {
    return {
      searchCategory: '',
      selectedCategories: []
    }
  },

  watch: {
    selectedCategories() {
      this.$emit('update:categoryFilters', this.selectedCategories)
      this.$emit('filtersChanged')
    }
  },

  methods: {
    removeCategoryFilter(category) {
      this.selectedCategories = this.selectedCategories.filter(function(selectedCategory) {
        return selectedCategory.id !== category.id
      })
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/components/protected/archive/Sidebar.scss"></style>
