import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import Protected from '@/views/Protected'
import Page from '@/views/protected/Page'
import Archive from '@/views/protected/Archive'
import Single from '@/views/protected/Single'
import NotFound from '@/views/NotFound-404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Protected,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Page
      },
      {
        path: ':slug',
        name: 'Page',
        component: Page
      },
      {
        path: 'archief/:postType',
        name: 'Archive',
        component: Archive
      },
      {
        path: 'archief/:postType/:slug',
        name: 'Single',
        component: Single
      }
    ]
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const availableSites = process.env.VUE_APP_AVAILABLE_DOMAINS.split(',')
  if (!availableSites.includes(window.location.hostname) && to.name !== 'NotFound') {
    next({ name: 'NotFound' })
  } else {
    store.dispatch('wp/clear')
    next()
  }
})

export default router
