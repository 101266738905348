<template>
  <router-link custom v-slot="{ navigate, href }" :to="{ name: 'Page', params: { slug: postName } }">
    <a :href="href" class="is-block mb-5" alt="">
    <div :class="[
      'cus-fases columns ',
      { 'cus-has-link': postName }
    ]" @click="navigate" style="90vh" >
      <div :class="[
        'column ',
        {'cus-fase-current': fase.current}
      ]" v-for="(fase, faseKey) in fases" :key="faseKey">
        <div class="cus-fase">
          <div class="cus-fase-arrow">
            <div class="cus-fase-start-arrow" :style="`border-left-color:${parentBackgroundColor}`"></div>
            <div class="cus-fase-end-arrow"></div>
            {{ fase.name }}
          </div>
        </div>
      </div>
    </div>
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'ProjectFases',

  props: {
    fases: [String, Array],
    parentBackgroundColor: String,
    postName: [String, Object]
  },
}
</script>

<style lang="scss" src="@/assets/scss/components/protected/blocks/ProjectFases.scss"></style>
