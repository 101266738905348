<template>
  <b-navbar class="cus-navbar" wrapper-class="container">
    <template slot="brand">
      <b-navbar-item class="cus-logo" tag="router-link" :to="{ name: 'Home' }" >
        <img v-if="$store.getters['wp/hasGeneralAcfData']" :src="$store.getters['wp/acf'].logo.url">
      </b-navbar-item>
    </template>

    <template slot="start">
      <component
      :is="menuItem.child_items && menuItem.child_items.length ? 'b-navbar-dropdown' : 'b-navbar-item'"
      v-for="menuItem in $store.getters['wp/menu/primaryMenuItems']"
      :key="menuItem.ID"
      tag="router-link"
      :to="routerTo(menuItem)">
        <template #label v-if="menuItem.child_items && menuItem.child_items.length">
          {{ menuItem.title }}
        </template>

        <template v-if="menuItem.child_items && menuItem.child_items.length">
          <b-navbar-item
            tag="router-link"
            :to="routerTo(subMenuItem)"
            v-for="subMenuItem in menuItem.child_items"
            :key="subMenuItem.ID">
            {{ subMenuItem.title }}
          </b-navbar-item>
        </template>

        <template v-else>
          {{ menuItem.title }}
        </template>
      </component>
    </template>

    <template slot="burger" slot-scope="{isOpened , toggleActive}">
      <a @click="toggleActive" role="button" :aria-label="isOpened ? 'menu sluiten' : 'menu'" :aria-expanded="isOpened ? 'true' : 'false'" :class="[
        'navbar-burger burger',
        {'is-active' : isOpened}
      ]">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </template>


    <template slot="end">
      <div class="cus-social-links has-text-right">
        <a v-if="$store.getters['wp/acf'].instagram" alt="Delen op Instagram..." :href="$store.getters['wp/acf'].instagram" target="_blank">
          <b-icon size="is-large" pack="fab" icon="instagram-square"/>
        </a>
        <a v-if="$store.getters['wp/acf'].twitter" alt="Delen op Twitter..." :href="$store.getters['wp/acf'].twitter" target="_blank">
          <b-icon size="is-large" pack="fab" icon="twitter-square"/>
        </a>
        <a v-if="$store.getters['wp/acf'].facebook" alt="Delen op Facebook..." :href="$store.getters['wp/acf'].facebook" target="_blank">
          <b-icon size="is-large" pack="fab" icon="facebook-square"/>
        </a>
        <a v-if="$store.getters['wp/acf'].linkedin" alt="Delen op LinkedIn..." :href="$store.getters['wp/acf'].linkedin" target="_blank">
          <b-icon size="is-large" pack="fab" icon="linkedin"/>
        </a>
        <a v-if="$store.getters['wp/acf'].youtube" alt="Delen op Youtube..." :href="$store.getters['wp/acf'].youtube" target="_blank">
          <b-icon size="is-large" pack="fab" icon="youtube-square"/>
        </a>
      </div>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar-Component',

  methods: {
    routerTo(menuItem) {
      if (menuItem.advanced_custom_fields && menuItem.advanced_custom_fields.is_archive) {
        return {
          name: 'Archive',
          params: {
            postType: menuItem.slug
          }
        }
      }
      if (menuItem.slug == 'home') {
        return {
          name: 'Home'
        }
      }
      return {
        name: 'Page',
        params: {
          slug: menuItem.slug
        }
      }
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/components/protected/Navbar.scss"></style>
