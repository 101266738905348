import axios from 'axios'

export default {
  namespaced: true,

  state: {
    primaryMenu: JSON.parse(sessionStorage.getItem('wp.menus.primaryMenu')) || '',
    footerMenu: JSON.parse(sessionStorage.getItem('wp.menus.footerMenu')) || '',
  },

  getters: {
    hasPrimaryMenu(state) {
      return state.primaryMenu
    },

    primaryMenuItems(state) {
      return state.primaryMenu.items
    },

    hasFooterMenu(state) {
      return state.footerMenu
    },

    footerMenuItems(state) {
      return state.footerMenu.items
    },
  },

  mutations: {
    storePrimaryMenu(state, primaryMenu) {
      state.primaryMenu = primaryMenu
      sessionStorage.setItem('wp.menus.primaryMenu', JSON.stringify(primaryMenu))
    },

    storeFooterMenu(state, footerMenu) {
      state.footerMenu = footerMenu
      sessionStorage.setItem('wp.menus.footerMenu', JSON.stringify(footerMenu))
    },
  },

  actions: {
    getPrimaryMenu({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/menus/v1/locations/primary_menu`)
        .then((response) => {
          commit('storePrimaryMenu', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getFooterMenu({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/menus/v1/locations/footer_menu`)
        .then((response) => {
          commit('storeFooterMenu', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
  },

  modules: {
  }
}
