<template>
  <section class="cus-footer section">
    <div class="container">
      <div class="columns">
        <div class="column" v-if="showMenu">
          <h3 class="cus-menulabel">Menu</h3>
          <b-menu class="cus-menu">
            <b-menu-list>
              <b-menu-item
                tag="router-link"
                :to="routerTo(menuItem)"
                v-for="menuItem in $store.getters['wp/menu/footerMenuItems']"
                :key="menuItem.ID"
                :label="menuItem.title"/>
            </b-menu-list>
          </b-menu>
        </div>

        <div class="column" v-if="showSocialMedia || showText">
          <div :class="[
            'block',
            `has-text-${socialMediaAndTextAlignment}`
          ]" v-if="showSocialMedia">
            <a class="has-text-light" v-if="$store.getters['wp/acf'].instagram" :href="$store.getters['wp/acf'].instagram" target="_blank" alt="Delen op Instagram...">
              <b-icon size="is-large" pack="fab" icon="instagram-square"/>
            </a>
            <a class="has-text-light" v-if="$store.getters['wp/acf'].twitter" :href="$store.getters['wp/acf'].twitter" target="_blank" alt="Delen op Twitter...">
              <b-icon size="is-large" pack="fab" icon="twitter-square"/>
            </a>
            <a class="has-text-light" v-if="$store.getters['wp/acf'].facebook" :href="$store.getters['wp/acf'].facebook" target="_blank" alt="Delen op Facebook...">
              <b-icon size="is-large" pack="fab" icon="facebook-square"/>
            </a>
            <a class="has-text-light" v-if="$store.getters['wp/acf'].linkedin" :href="$store.getters['wp/acf'].linkedin" target="_blank" alt="Delen op LinkedIn...">
              <b-icon size="is-large" pack="fab" icon="linkedin"/>
            </a>
            <a class="has-text-light" v-if="$store.getters['wp/acf'].youtube" :href="$store.getters['wp/acf'].youtube" target="_blank" alt="Delen op Youtube...">
              <b-icon size="is-large" pack="fab" icon="youtube-square"/>
            </a>
          </div>

          <div class="has-text-light has-text-right content is-size-7" v-if="showText && text" v-html="text"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer-Component',

  computed: {
    showMenu() {
      return this.$store.getters['wp/acf'].footer_show_menu ?? false
    },

    showSocialMedia() {
      return this.$store.getters['wp/acf'].footer_show_social_media ?? false
    },

    showText() {
      return this.$store.getters['wp/acf'].footer_show_text_box ?? false
    },

    text() {
      return this.$store.getters['wp/acf'].footer_text_box ?? ''
    },

    socialMediaAndTextAlignment() {
      return this.$store.getters['wp/acf'].footer_text_and_social_media_alignment ?? 'left'
    }
  },

  methods: {
    routerTo(menuItem) {
      if (menuItem.advanced_custom_fields && menuItem.advanced_custom_fields.is_archive) {
        return {
          name: 'Archive',
          params: {
            postType: menuItem.slug
          }
        }
      }
      if (menuItem.slug == 'home') {
        return {
          name: 'Home'
        }
      }
      return {
        name: 'Page',
        params: {
          slug: menuItem.slug
        }
      }
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/components/protected/Footer.scss"></style>
