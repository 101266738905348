import axios from 'axios'

export default {
  namespaced: true,

  state: {
    page: {}
  },

  getters: {
    hasPage(state) {
      return !!Object.keys(state.page).length
    },

    page(state) {
      return state.page
    },
  },

  mutations: {
    clearPage(state) {
      state.page = {}
    },

    storePage(state, page) {
      state.page = page[0]
    },
  },

  actions: {
    getPage({commit}, slug) {
      return new Promise((resolve, reject) => {
        commit('clearPage')
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/pages`, {
          params: {
            slug: slug
          }
        })
        .then((response) => {
          if (response.data.length) {
            commit('storePage', response.data)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },

  modules: {
  }
}
