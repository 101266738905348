var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"custom":"","to":{ name: 'Page', params: { slug: _vm.postName } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
return [_c('a',{staticClass:"is-block mb-5",attrs:{"href":href,"alt":""}},[_c('div',{class:[
    'cus-fases columns ',
    { 'cus-has-link': _vm.postName }
  ],staticStyle:{},on:{"click":navigate}},_vm._l((_vm.fases),function(fase,faseKey){return _c('div',{key:faseKey,class:[
      'column ',
      {'cus-fase-current': fase.current}
    ]},[_c('div',{staticClass:"cus-fase"},[_c('div',{staticClass:"cus-fase-arrow"},[_c('div',{staticClass:"cus-fase-start-arrow",style:(("border-left-color:" + _vm.parentBackgroundColor))}),_c('div',{staticClass:"cus-fase-end-arrow"}),_vm._v(" "+_vm._s(fase.name)+" ")])])])}),0)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }