import axios from 'axios'

export default {
  namespaced: true,

  state: {
    post: {}
  },

  getters: {
    hasPost(state) {
      return !!Object.keys(state.post).length
    },

    post(state) {
      return state.post
    },
  },

  mutations: {
    clearPost(state) {
      state.post = {}
    },

    storePost(state, post) {
      state.post = post[0]
    },
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    getPosts({rootGetters}, data) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/${data.type}`, {
          params: data.params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getPost({commit}, data) {
      return new Promise((resolve, reject) => {
        commit('clearPost')
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/${data.type}`, {
          params: {
            slug: data.slug
          }
        })
        .then((response) => {
          if (response.data.length) {
            commit('storePost', response.data)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
  },

  modules: {
  }
}
