<template>
  <div class="content">
    <h1>404 Not Found</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
