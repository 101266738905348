<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading"/>

    <Hero
    v-if="$store.getters['wp/page/hasPage']"
    :title="$store.getters['wp/page/page'].title.rendered"
    :subtitle="$store.getters['wp/page/page'].advanced_custom_fields.description"
    :image="$store.getters['wp/page/page'].featured_media_links.original"/>

    <section class="cus-archive cus-content section content" v-if="$store.getters['wp/page/hasPage']">
      <div class="container">
        <div v-show="!isLoading" v-html="contentHtml" class="mb-5"></div>

        <div class="columns is-multiline is-mobile">
          <div class="column is-full-mobile is-full-tablet is-narrow-desktop" v-show="hasFilters">
            <Sidebar
            v-if="$store.getters['wp/page/page'].advanced_custom_fields.show_archive_filters"
            v-on:filtersChanged="filtersChanged()"
            :categoryFilters.sync="categoryFilters"/>
          </div>

          <div class="column is-relative">
            <b-loading :is-full-page="false" :active="isFetchingPosts"/>

            <div class="content">
              <div class="cus-articles columns is-multiline is-mobile">
                <div
                :class="[
                  'column is-flex mb-4',
                  {'is-half': hasHighlightedLayout && currentPage == 1 && articleKey <= 1},
                  {'is-half-mobile is-one-third-tablet': !hasHighlightedLayout || currentPage > 1 || articleKey > 1},
                ]"
                v-for="(article, articleKey) in $store.getters['wp/archive/posts']"
                :key="article.id">
                  <router-link
                  class="cus-article-link"
                  custom
                  v-slot="{ href }"
                  :to="{ name: 'Single', params: { postType: $route.params.postType, slug: article.slug } }">
                    <div class="cus-article">
                      <a :href="href">
                      <div class="card">
                        <div class="card-image">
                          <div class="image is-4by3">
                            <img :src="(article.featured_media_links.medium_large ? article.featured_media_links.medium_large : article.featured_media_links.original)">
                          </div>
                        </div>
                      </div>

                      <h3 :class="[
                        'title mb-0 mt-4',
                        {'is-4': hasHighlightedLayout && currentPage == 1 && articleKey <= 1},
                        {'is-5': !hasHighlightedLayout || currentPage > 1 || articleKey > 1}
                      ]">
                        {{ article.title.rendered }}
                      </h3>
                      </a>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <div v-if="$store.getters['wp/archive/postsCount'] > perPage" class="box mt-5">
              <b-pagination
                :total="$store.getters['wp/archive/postsCount']"
                :per-page="perPage"
                v-model="currentPage"
                :range-before="1"
                :range-after="1"
                order="is-centered"
                icon-pack="far"
                icon-prev="angle-left"
                icon-next="angle-right"
                aria-next-label="Volgende pagina"
                aria-previous-label="Vorige pagina"
                aria-page-label="Pagina"
                aria-current-label="Huidige pagina"
                @change="changePaginationPage">
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import Hero from '@/components/protected/Hero'
import Sidebar from '@/components/protected/archive/Sidebar'

export default {
  name: 'Archive',

  components: {
    Hero,
    Sidebar
  },

  computed: {
    hasActiveFilters() {
      if (this.categoryFilters.length) {
        return true
      }
      return false
    },

    hasFilters() {
      if (this.$store.getters['wp/archive/hasCategories']) {
        return true
      }
      return false
    },

    hasHighlightedLayout() {
      if (!this.hasActiveFilters && this.highlightedLayout) {
        return true
      }
      return false
    },

    pageContent() {
      if (this.$store.getters['wp/page/hasPage']) {
        return this.$store.getters['wp/page/page'].content.rendered
      }
      return ''
    },
  },

  data() {
    return {
      isLoading: true,
      isFetchingPosts: true,
      highlightedLayout: true,
      currentPage: 1,
      perPage: 5,
      selectedOrder: 'date-desc',
      categoryFilters: [],
      contentHtml: null
    }
  },

  watch: {
    '$route'() {
      this.init()
    },

    selectedOrder() {
      this.getPosts()
      this.currentPage = 1
    },
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      await this.$store.dispatch('wp/page/getPage', this.$route.params.postType)
      if (this.$store.getters['wp/page/hasPage']) {
        this.contentHtml = this.pageContent
        this.isLoading = false
      } else {
        this.$router.push({ name: 'NotFound' })
      }

      this.getPosts()
      this.getCategories()
    },

    changePaginationPage(page) {
      this.currentPage = page
      this.getPosts()
    },

    async getPosts() {
      this.isFetchingPosts = true
      this.perPage = this.highlightedLayout && !this.hasActiveFilters ? 5 : 6
      await this.$store.dispatch('wp/archive/getAllPosts', {
        type: this.$route.params.postType,
        params: {
          page: this.currentPage,
          per_page: this.perPage,
          orderby: this.selectedOrder.split('-')[0],
          order: this.selectedOrder.split('-')[1],
          'nieuwsartikel-category': this.categoryFilters.map(categoryFilter => categoryFilter.id)
        }
      })
      this.total = this.$store.getters['wp/archive/postsCount']
      this.isFetchingPosts = false
    },

    getCategories() {
      this.$store.dispatch('wp/archive/getAllCategories', {
        per_page: 100,
        hide_empty: true
      })
    },

    async filtersChanged() {
      this.currentPage = 1
      await this.getPosts()
    },

    moment(construct) {
      return moment(construct)
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/views/protected/Archive.scss"></style>
