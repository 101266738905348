import axios from 'axios'

export default {
  namespaced: true,

  state: {
    types: []
  },

  getters: {
    hasTypes(state) {
      return state.types.length
    },

    types(state) {
      return state.types
    },

    publicArchiveTypes(state) {
      const publicWPArchives = process.env.VUE_APP_WP_ARCHIVES.split(',')
      let types = []
      for (let index = 0; index < publicWPArchives.length; index++) {
        const publicWPArchive = publicWPArchives[index]
        if (publicWPArchive in state.types) {
          types.push(state.types[publicWPArchive])
        }
      }
      return types
    },

    getTypeBySlug: (state) => (slug) => {
      return state.types.find(page => page.slug === slug)
    }
  },

  mutations: {
    storeAllTypes(state, types) {
      state.types = types
    }
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    getAllTypes({commit, rootGetters}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/types`)
        .then((response) => {
          commit('storeAllTypes', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },

  modules: {
  }
}
