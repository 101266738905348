<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',

  created() {
    if (process.env.VUE_APP_ASK_VISIT_PASSWORD == true) {
      var response = prompt('Password')
      if (response !== process.env.VUE_APP_VISIT_PASSWORD) {
        window.location.replace(process.env.VUE_APP_VISIT_REDIRECT_URL)
      }
    }
  }
}
</script>
