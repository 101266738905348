<template>
  <div class="hero is-primary" :style="image ? `background-image: url(${image})` : ''">
    <div class="hero-background-overlay"></div>
    <div class="hero-body pt-6 pb-6">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="title is-size-1" v-html="title">
            </h1>
            <h2 class="subtitle" v-html="subtitle">
            </h2>
          </div>
          <div class="cus-contact-person column is-narrow has-text-centered">
            <figure class="image mb-4">
              <img class="is-rounded" :src="$store.getters['wp/acf'].contact_person_photo.url" :alt="$store.getters['wp/acf'].contact_person_name">
            </figure>
            <div v-if="$store.getters['wp/acf'].contact_person_title" class="box pt-2 pb-2 is-radiusless has-background-primary has-text-light">
              <p>{{ $store.getters['wp/acf'].contact_person_title }}</p>
              <p>
                <a :href="`mailto:${$store.getters['wp/acf'].contact_person_email}`">
                  {{ $store.getters['wp/acf'].contact_person_email }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero-Component',

  props: {
    isLoading: Boolean,
    title: String,
    subtitle: String,
    image: [String, Boolean]
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/components/protected/Hero.scss"></style>
