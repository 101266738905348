import axios from 'axios'

import TypesModule from './types'
import ArchiveModule from './archive'
import PageModule from './page'
import PostModule from './post'
import MenuModule from './menu'
import GeoniusMapRegistrationsModule from './geonius-map-registrations'
import contactForm7 from './contactForm7'

export default {
  namespaced: true,

  state: {
    name: sessionStorage.getItem('wp.index.name') || '',
    description: sessionStorage.getItem('wp.index.description') || '',
    acf: JSON.parse(sessionStorage.getItem('wp.index.acf')) || {},
  },

  getters: {
    hostname() {
      if (window.location.hostname == 'omgevingscommunicatie.z6.web.core.windows.net') {
        return 'klimaatklaarhoevenbraakzuid' // For demo purposes (needs to be deleted in future)
      }
      if (isNaN(window.location.hostname.slice(-1))) {
        return window.location.hostname.substr(0, window.location.hostname.lastIndexOf('.'))
      }
      return window.location.hostname
    },

    hasGeneralData(state) {
      return state.name.length || state.description.length ? true : false
    },

    hasGeneralAcfData(state) {
      return !!Object.keys(state.acf).length
    },

    name(state) {
      return state.name || ''
    },

    description(state) {
      return state.description || ''
    },

    acf(state) {
      return state.acf
    }
  },

  mutations: {
    storeGeneralData(state, generalData) {
      state.name = generalData.name
      state.description = generalData.description
      sessionStorage.setItem('wp.index.name', generalData.name)
      sessionStorage.setItem('wp.index.description', generalData.description)
    },

    storeGeneralAcfData(state, generalAcfData) {
      state.acf = generalAcfData
      sessionStorage.setItem('wp.index.acf', JSON.stringify(generalAcfData))
    },
  },

  actions: {
    getGeneralData({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json`)
        .then((response) => {
          commit('storeGeneralData', response.data)
          dispatch('getGeneralAcfData')
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getGeneralAcfData({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/v1/acf`)
        .then((response) => {
          commit('storeGeneralAcfData', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    clear({commit}) {
      commit('wp/page/clearPage', null, { root: true })
      commit('wp/post/clearPost', null, { root: true })
      commit('wp/archive/clearAllPosts', null, { root: true })
      commit('wp/archive/clearAllCategories', null, { root: true })
    }
  },

  modules: {
    types: TypesModule,
    archive: ArchiveModule,
    page: PageModule,
    post: PostModule,
    menu: MenuModule,
    geoniusMapRegistrations: GeoniusMapRegistrationsModule,
    contactForm7: contactForm7
  }
}
