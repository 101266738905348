<template>
  <div>
    <Navbar/>
    <router-view/>
    <Footer/>
    <CopyrightFooter/>

    <b-loading :is-full-page="true" :active="isLoading"></b-loading>
  </div>
</template>

<script>
import Navbar from '@/components/protected/Navbar'
import Footer from '@/components/protected/Footer'
import CopyrightFooter from '@/components/protected/CopyrightFooter'

export default {
  name: 'Protected',

  data() {
    return {
      isLoading: true
    }
  },

  components: {
    Navbar,
    Footer,
    CopyrightFooter
  },

  created() {
    this.init()
  },

  watch: {
    '$route'() {
      this.setPageTitle()
    }
  },

  methods: {
    async init() {
      if (!this.$store.getters['wp/hasGeneralData']) {
        await this.getGeneralSiteData()
      }

      if (this.$store.getters['wp/acf'].chat_enabled) {
        this.initChat()
      }

      if (!this.$store.getters['wp/menu/hasPrimaryMenu']) {
        await this.getPrimaryMenu()
      }

      if (!this.$store.getters['wp/menu/hasFooterMenu']) {
        await this.getFooterMenu()
      }

      this.setPageTitle()

      this.isLoading = false
    },

    async getGeneralSiteData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('wp/getGeneralData')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    async getPrimaryMenu() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('wp/menu/getPrimaryMenu')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    async getFooterMenu() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('wp/menu/getFooterMenu')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    initChat() {
      // eslint-disable-next-line no-unused-vars
      let Tawk_API = Tawk_API||{}, Tawk_LoadStart = new Date()

      let s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]
      s1.async=true
      s1.src=this.$store.getters['wp/acf'].chat_embed_code
      s1.charset='UTF-8'
      s1.setAttribute('crossorigin','*')
      s0.parentNode.insertBefore(s1,s0)
    },

    setPageTitle() {
      const slug = this.$route.params.slug
      const postType = this.$route.params.postType

      let siteTitle = ''
      if (this.$store.getters['wp/hasGeneralData']) {
        siteTitle = this.$store.getters['wp/name']
      }

      let title = ''
      if (this.$route.name == 'Page') {
        title = `${slug.charAt(0).toUpperCase() + slug.slice(1)} | ${siteTitle}`
      } else if (this.$route.name == 'Archive') {
        title = `${postType.charAt(0).toUpperCase() + postType.slice(1)} | ${siteTitle}`
      } else if (this.$route.name == 'Single') {
        const readableSlug = slug.replace(/-/g, ' ')
        title =  `${readableSlug.charAt(0).toUpperCase() + readableSlug.slice(1)} | ${siteTitle}`
      } else {
        title = `Home | ${siteTitle}`
      }

      window.document.title = title
    }
  }
}
</script>
