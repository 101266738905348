import Vue from 'vue'
import Vuex from 'vuex'

import GeoserverModule from '@/store/geoserver/index'
import AuthorizationModule from '@/store/authorization/index'
import WordpressModule from '@/store/wp/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    geoserver: GeoserverModule,
    authorization: AuthorizationModule,
    wp: WordpressModule,
  }
})
