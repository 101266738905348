var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.isLoading}}),(_vm.$store.getters['wp/page/hasPage'])?_c('Hero',{attrs:{"title":_vm.$store.getters['wp/page/page'].title.rendered,"subtitle":_vm.$store.getters['wp/page/page'].advanced_custom_fields.description,"image":_vm.$store.getters['wp/page/page'].featured_media_links.original}}):_vm._e(),(_vm.$store.getters['wp/page/hasPage'])?_c('section',{staticClass:"cus-archive cus-content section content"},[_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"mb-5",domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasFilters),expression:"hasFilters"}],staticClass:"column is-full-mobile is-full-tablet is-narrow-desktop"},[(_vm.$store.getters['wp/page/page'].advanced_custom_fields.show_archive_filters)?_c('Sidebar',{attrs:{"categoryFilters":_vm.categoryFilters},on:{"filtersChanged":function($event){return _vm.filtersChanged()},"update:categoryFilters":function($event){_vm.categoryFilters=$event},"update:category-filters":function($event){_vm.categoryFilters=$event}}}):_vm._e()],1),_c('div',{staticClass:"column is-relative"},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isFetchingPosts}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"cus-articles columns is-multiline is-mobile"},_vm._l((_vm.$store.getters['wp/archive/posts']),function(article,articleKey){return _c('div',{key:article.id,class:[
                'column is-flex mb-4',
                {'is-half': _vm.hasHighlightedLayout && _vm.currentPage == 1 && articleKey <= 1},
                {'is-half-mobile is-one-third-tablet': !_vm.hasHighlightedLayout || _vm.currentPage > 1 || articleKey > 1} ]},[_c('router-link',{staticClass:"cus-article-link",attrs:{"custom":"","to":{ name: 'Single', params: { postType: _vm.$route.params.postType, slug: article.slug } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('div',{staticClass:"cus-article"},[_c('a',{attrs:{"href":href}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image"},[_c('div',{staticClass:"image is-4by3"},[_c('img',{attrs:{"src":(article.featured_media_links.medium_large ? article.featured_media_links.medium_large : article.featured_media_links.original)}})])])]),_c('h3',{class:[
                      'title mb-0 mt-4',
                      {'is-4': _vm.hasHighlightedLayout && _vm.currentPage == 1 && articleKey <= 1},
                      {'is-5': !_vm.hasHighlightedLayout || _vm.currentPage > 1 || articleKey > 1}
                    ]},[_vm._v(" "+_vm._s(article.title.rendered)+" ")])])])]}}],null,true)})],1)}),0)]),(_vm.$store.getters['wp/archive/postsCount'] > _vm.perPage)?_c('div',{staticClass:"box mt-5"},[_c('b-pagination',{attrs:{"total":_vm.$store.getters['wp/archive/postsCount'],"per-page":_vm.perPage,"range-before":1,"range-after":1,"order":"is-centered","icon-pack":"far","icon-prev":"angle-left","icon-next":"angle-right","aria-next-label":"Volgende pagina","aria-previous-label":"Vorige pagina","aria-page-label":"Pagina","aria-current-label":"Huidige pagina"},on:{"change":_vm.changePaginationPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }