<template>
  <div>
    <div class="cus-horizontal-card card">
      <div class="is-relative">
        <b-loading :active="isMapLoading" :is-full-page="false"/>
        <div ref="map" class="cus-map"></div>
      </div>
      <div class="card-content">
        <div class="content" v-html="childContent">
        </div>
      </div>
    </div>

    <b-modal
      v-model="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      @close="cancel()">
      <div class="modal-card" style="min-width: 400px;width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Melding maken</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Geplaatste locatie">
            <div ref="modalMap" class="cus-modal-map"></div>
          </b-field>

          <b-field label="Naam">
            <b-input v-model="fields.name" required type="text"></b-input>
          </b-field>

          <b-field label="Straatnaam">
            <b-input v-model="fields.street" required type="text"></b-input>
          </b-field>

          <b-field label="E-mailadres (optioneel)">
            <b-input v-model="fields.email" type="email"></b-input>
          </b-field>

          <b-field label="Telefoonnummer (optioneel)">
            <b-input v-model="fields.tel"  type="tel"></b-input>
          </b-field>

          <b-field label="Opmerking">
            <b-input v-model="fields.note" maxlength="1000" type="textarea"></b-input>
          </b-field>

          <p>Uw melding wordt direct aan ons verstuurd en is niet zichtbaar voor anderen</p>
        </section>
        <footer class="modal-card-foot">
          <div class="buttons is-right">
            <b-button label="Annuleren" @click="cancel()" :disabled="isProcessing"/>
            <b-button label="Versturen" :loading="isProcessing" type="is-primary" @click="create()" />
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as L from "leaflet"
require('leaflet/dist/leaflet.css')

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'Map',

  props: {
    latitude: [String, Number],
    longitude: [String, Number],
    geoJSONUrl: String,
    geoJSON: String,
    childContent: String
  },

  data() {
    return {
      isModalActive: false,
      isProcessing: false,
      isMapLoading: true,
      fields: {
        name: '',
        street: '',
        email: '',
        tel: '',
        note: '',
        lat: '',
        lng: '',
      }
    }
  },

  mounted() {
    const self = this
    setTimeout(() => {
      self.init()
    }, 500)
  },

  methods: {
    async init() {
      const self = this
      let map = L.map(this.$refs.map, {
        zoomControl: false,
        minZoom: 1,
        maxBounds: [
          [90,-180],
          [-90, 180]
        ],
        maxBoundsViscosity: 1.0
      })

      L.tileLayer('https://api.mapbox.com/styles/v1/berry807/cknrrv6op10lv17ufthrbeb99/tiles/512/{z}/{x}/{y}@2x?access_token={accessToken}&renderWorldCopies=false', {
        attribution: 'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 21,
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoiYmVycnk4MDciLCJhIjoiY2trOGF6ZWp1MG00dzJvbXZicGxzaGJ3cCJ9.yxc1jMETjpQ0IIkIamYkmw',
        noWrap: true,
      }).addTo(map)

      if (this.geoJSON) {
        let mapGeoJSON = L.geoJSON(JSON.parse(this.geoJSON))

        map.addLayer(mapGeoJSON)
        map.fitBounds(mapGeoJSON.getBounds())
      } else {
        map.setView([
          this.latitude ? this.latitude : 52.092876,
          this.longitude ? this.longitude : 5.104480
        ], this.latitude && this.longitude ? 15 : 7)
      }

      map.on('click', function(e) {
        self.isModalActive = true
        setTimeout(() => {
          self.fields.lat = e.latlng.lat
          self.fields.lng = e.latlng.lng
          self.initModalMap(e.latlng.lat, e.latlng.lng, e.target._zoom)
        }, 100)
      })

      this.isMapLoading = false
    },

    initModalMap(lat, lng, zoomLevel) {
      let map = L.map(this.$refs.modalMap, {
        zoomControl: false,
        minZoom: 1,
        maxBounds: [
          [90,-180],
          [-90, 180]
        ],
        maxBoundsViscosity: 1.0
      })

      L.tileLayer('https://api.mapbox.com/styles/v1/berry807/cknrrv6op10lv17ufthrbeb99/tiles/512/{z}/{x}/{y}@2x?access_token={accessToken}&renderWorldCopies=false', {
        attribution: 'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 21,
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoiYmVycnk4MDciLCJhIjoiY2trOGF6ZWp1MG00dzJvbXZicGxzaGJ3cCJ9.yxc1jMETjpQ0IIkIamYkmw',
        noWrap: true,
      }).addTo(map)

      if (this.geoJSON) {
        let mapGeoJSON = L.geoJSON(JSON.parse(this.geoJSON))

        map.addLayer(mapGeoJSON)
        map.fitBounds(mapGeoJSON.getBounds())
      } else {
        map.setView([
          this.latitude ? this.latitude : 52.092876,
          this.longitude ? this.longitude : 5.104480
        ], this.latitude && this.longitude ? 15 : 7)
      }

      L.marker([lat, lng]).addTo(map)

      map.dragging.disable()
      map.touchZoom.disable()
      map.doubleClickZoom.disable()
      map.scrollWheelZoom.disable()
      map.boxZoom.disable()
      map.keyboard.disable()

      map.setView([lat, lng], zoomLevel)
    },

    cancel() {
      this.reset()
      this.isModalActive = false
    },

    async create() {
      this.isProcessing = true
      const self = this
      await this.$store.dispatch('wp/geoniusMapRegistrations/createRegistration', {
        name: this.fields.name,
        street: this.fields.street,
        email: this.fields.email,
        tel: this.fields.tel,
        note: this.fields.note,
        lat: this.fields.lat,
        lng: this.fields.lng,
      }).then(() => {
        self.$buefy.snackbar.open({
          message: `Je opmerking is verstuurd!`,
          position: 'is-top',
          indefinite: true,
        })
      })
      this.isModalActive = false
      this.isProcessing = false
      this.reset()
    },

    reset() {
      this.fields = {
        name: '',
        street: '',
        email: '',
        tel: '',
        note: '',
        lat: '',
        lng: '',
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/components/protected/blocks/Map.scss"></style>
