import axios from 'axios'

export default {
  namespaced: true,

  state: () => ({

  }),

  getters: {
  },

  actions: {
    getGeoJson({commit}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`https://geonius.northeurope.cloudapp.azure.com/geoserver/${params.namespace}/ows?service=WFS&version=1.0.0&request=GetFeature&maxFeatures=50&outputFormat=application%2Fjson&srsName=EPSG:4326`, {
          auth: {
            username: 'QTG',
            password: 'Geonius123'
          },
          params: params.params
        }).then((response) => {
          commit('getGeoJsonSuccess', response)
          resolve(response)
        }).catch((error) => {
          commit('getGeoJsonError')
          reject(error)
        })
      })
    }
  },

  mutations: {
    getGeoJsonSuccess() {},
    getGeoJsonError() {},
  },

  modules: {
  }
}
