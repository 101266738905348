import axios from 'axios'

export default {
  namespaced: true,

  state: {
    postsCount: 0,
    posts: [],
    categoriesCount: 0,
    categories: [],
  },

  getters: {
    postsCount(state) {
      return state.postsCount
    },

    posts(state) {
      return state.posts || []
    },

    hasCategories(state) {
      return state.categories.length
    },

    categoriesCount(state) {
      return state.categoriesCount
    },

    categories(state) {
      return state.categories || []
    },
  },

  mutations: {
    clearAllPosts(state) {
      state.posts = []
    },

    storeAllPosts(state, posts) {
      state.posts = posts
    },

    storePostsCount(state, postsCount) {
      state.postsCount = postsCount
    },

    clearAllCategories(state) {
      state.categories = []
    },

    storeAllCategories(state, categories) {
      state.categories = categories
    },

    storeCategoriesCount(state, categoriesCount) {
      state.categoriesCount = categoriesCount
    },
  },

  actions: {
    getAllPosts({commit}, data) {
      return new Promise((resolve, reject) => {
        const defaultParams = {
          page: 1,
          per_page: 10,
          orderby: 'date',
          order: 'desc'
        }
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/${data.type}`, {
          params: { ...defaultParams, ...data.params },
        })
        .then((response) => {
          commit('storePostsCount', response.headers['x-wp-total'])
          commit('storeAllPosts', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getAllCategories({commit}, params) {
      return new Promise((resolve, reject) => {
        const defaultParams = {
          page: 1,
          per_page: 10,
          orderby: 'name',
          order: 'asc'
        }
        axios.get(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/nieuwsartikel-category`, {
          params: { ...defaultParams, ...params },
        })
        .then((response) => {
          commit('storeCategoriesCount', response.headers['x-wp-total'])
          commit('storeAllCategories', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },

  modules: {
  }
}
