<template>
  <div ref="root">
    <form @submit.prevent="submitForm" v-html="childContent" novalidate/>
  </div>
</template>

<script>
export default {
  name: 'Form',

  props: {
    formId: [String, Number],
    childContent: String
  },

  mounted() {
    const formFields = this.$refs.root.querySelectorAll('.wpcf7-form-control')
    for (let i = 0; i < formFields.length; i++) {
      formFields[i].required = true
    }
  },

  methods: {
    async submitForm(event) {
      let fields = []
      for (let i = 0; i < event.target.elements.length; i++) {
        const formField = event.target.elements[i]
        if (formField.name !== '' || formField.name !== undefined) {
          fields[formField.name] = formField.value
        }
      }

      const self = this
      await this.$store.dispatch('wp/contactForm7/submitForm', {
        id: this.formId,
        fields: fields
      }).then((response) => {
        self.resetValidationStates()

        if (response.data.status == 'validation_failed') {
          self.$buefy.snackbar.open({
            type: 'is-danger',
            message: response.data.message,
            position: 'is-top',
            indefinite: true,
          })
          self.setValidationStates(response.data.invalid_fields)
          return
        }

        if (response.data.status == 'mail_failed') {
          self.$buefy.snackbar.open({
            type: 'is-danger',
            message: response.data.message,
            position: 'is-top',
            indefinite: true,
          })
          return
        }

        self.$buefy.snackbar.open({
          message: `Je mail is verstuurd!`,
          position: 'is-top',
          indefinite: false,
          duration: 5000,
        })
      })
    },

    resetValidationStates() {
      const fieldContainers = this.$refs.root.querySelectorAll('.field')

      for (let i = 0; i < fieldContainers.length; i++) {
        const fieldContainer = fieldContainers[i]
        const validationMessages = fieldContainer.querySelectorAll('.help')
        const fieldInputs = fieldContainer.querySelectorAll('input')

        for (let iVal = 0; iVal < validationMessages.length; iVal++) {
          validationMessages[iVal].remove()
        }
        for (let iInput = 0; iInput < fieldInputs.length; iInput++) {
          fieldInputs[iInput].classList.remove('is-danger')
        }
      }
    },

    setValidationStates(errorValidations) {
      for (let i = 0; i < errorValidations.length; i++) {
        const errorValidation = errorValidations[i]
        const fieldInput = this.$refs.root.querySelectorAll(`${errorValidation.into} input`)
        const fieldContainer = this.$refs.root.querySelectorAll(errorValidation.into)

        if (fieldContainer.length) {
          let el = document.createElement('p')
          el.className = 'help is-danger'
          el.innerHTML = errorValidation.message
          fieldContainer[0].closest('.field').insertBefore(el, fieldContainer.nextSibling)
        }

        if (fieldInput.length) {
          fieldInput[0].classList.add('is-danger')
        }
      }
    },
  }
}
</script>

<style>

</style>
