<template>
  <div class="cus-latest-custom-posts-cards mb-0 columns is-multiline">
    <div class="column" v-for="post in posts" :key="post.id">
      <router-link custom v-slot="{ href, navigate }" :to="{ name: 'Single', params: { postType: post.type, slug: post.slug } }">
        <div class="card">
          <a :href="href" @click="navigate">
            <div class="card-header">
              <p class="card-header-title" v-if="post.type == 'nieuwsartikelen'">
                {{ moment(post.date).format('DD-MM-YYYY') }}
              </p>
              <p class="card-header-title" v-if="post.type == 'participaties'">
                <span class="cus-progress-text mr-4" v-if="post.advanced_custom_fields.start_date">
                  <span v-if="moment().isBefore(moment(post.advanced_custom_fields.end_date))">
                    Nog
                    {{ moment(post.advanced_custom_fields.end_date).diff(moment(), 'days') }}
                    dagen
                  </span>
                  <span v-else>
                    {{ moment(post.advanced_custom_fields.end_date).diff(moment(), 'days') }}
                    dagen geleden
                  </span>
                </span>
                <progress
                class="progress is-secondary"
                :value="getDateDifferenceInPercentage(post.advanced_custom_fields.start_date, post.advanced_custom_fields.end_date)"
                max="100"
                v-if="post.advanced_custom_fields.start_date && post.advanced_custom_fields.end_date"/>
                <span v-if="!post.advanced_custom_fields.start_date || !post.advanced_custom_fields.end_date">
                  {{ moment(post.date).format('DD-MM-YYYY') }}
                </span>
              </p>
            </div>

          <div :class="[
            'card-image',
            {'has-background-grey-lighter': !post.featured_media}
          ]">
              <div class="image is-4by3">
                <img v-if="post.featured_media" :src="(post.featured_media_links.medium_large ? post.featured_media_links.medium_large : post.featured_media_links.original)">

                <b-icon v-if="!post.featured_media && post.type == 'nieuwsartikelen'" pack="fal" icon="newspaper"/>
                <b-icon v-if="!post.featured_media && post.type == 'participaties'" pack="fal" icon="user-friends"/>
              </div>
          </div>

          <div class="card-content">
              <p class="has-text-weight-bold has-text-tertiary mb-1">
                <span v-if="post.type == 'nieuwsartikelen'">Nieuws</span>
                <span v-if="post.type == 'participaties'">Participatie</span>
              </p>
              <h3 class="title mb-0 mt-1 is-5">
                {{ post.title.rendered }}
              </h3>

          </div>
          </a>
          <div v-if="post.type == 'participaties'">
              <div class="level pl-5 pr-5 mt-0 mb-5">
                <div class="level-left">
                  <div class="level-item">
                    <p class="has-text-black mb-0 has-text-weight-bold">
                      Delen
                    </p>
                  </div>
                </div>
                <div class="level-right">
                  <div class="block has-text-right">
                    <a class="has-text-primary" :href="`https://twitter.com/share?url=${url}${href}`" target="_blank" alt="Delen via Twitter...">
                      <b-icon pack="fab" size="is-size-4" icon="twitter-square"/>
                    </a>
                    <a class="has-text-primary" :href="`https://www.facebook.com/sharer/sharer.php?u=${url}${href}`" target="_blank" alt="Delen via Facebook...">
                      <b-icon pack="fab" size="is-size-4" icon="facebook-square"/>
                    </a>
                    <a class="has-text-primary" :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}${href}`" target="_blank" alt="Delen via LinkedIn...">
                      <b-icon pack="fab" size="is-size-4" icon="linkedin"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'LatestPostsCards',

  props: {
    maxCards: [Number, String]
  },

  data() {
    return {
      postsBacklog: []
    }
  },

  computed: {
    url() {
      return window.location.origin
    },

    maxNumberCards() {
      return parseInt(this.maxCards)
    },

    posts() {
      return this.postsBacklog.slice(0, this.maxNumberCards)
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    async init() {
      let newsArticles = []
      let participations = []

      await this.$store.dispatch('wp/post/getPosts', {
        type: 'nieuwsartikelen',
        params: {
          per_page: this.maxNumberCards
        }
      })
      .then((response) => {
        newsArticles = response.data
      })

      await this.$store.dispatch('wp/post/getPosts', {
        type: 'participaties',
        params: {
          per_page: this.maxNumberCards
        }
      })
      .then((response) => {
        participations = response.data
      })

      this.postsBacklog = newsArticles.concat(participations)
      this.postsBacklog.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
    },

    getDateDifferenceInPercentage(start, end) {
      const total = moment(end).unix() - moment(start).unix()
      const part = moment().unix() - moment(start).unix()
      const percent = part/total * 100
      return percent
    },

    moment(constructor = null, secondConstructor = null) {
      if (constructor && secondConstructor) {
        return moment(constructor, secondConstructor)
      }
      if (constructor) {
        return moment(constructor)
      }
      return moment()
    }
  }
}
</script>

<style>
  .cus-progress-text {
    white-space: nowrap;
  }

  .cus-latest-custom-posts-cards .card {
    height: 100%;
  }
</style>
