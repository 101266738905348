import axios from 'axios'

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    createRegistration({rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/v1/geonius-map-registrations/registration`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },

  modules: {
  }
}
