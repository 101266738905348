<template>
  <section class="section" :style="`background-color:${backgroundColor}`">
    <div :class="[
      {'container': !fullwidth}
    ]" v-html="childContent">
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section',

  props: {
    fullwidth: [String, Boolean],
    backgroundColor: String,
    childContent: String
  }
}
</script>
