import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: sessionStorage.getItem('authorization.token') || ''
  },
  getters: {
    authenticated(state) {
      return !!state.token.length
    },

    token(state) {
      return state.token
    }
  },
  mutations: {
    storeToken(state, token) {
      state.token = token
      sessionStorage.setItem('authorization.token', token)
    }
  },
  actions: {
    getToken({commit}) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/jwt-auth/v1/token`, {
          username: process.env.VUE_APP_API_USERNAME,
          password: process.env.VUE_APP_API_PASSWORD
        })
        .then((response) => {
          commit('storeToken', response.data.token)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },
  modules: {
  }
}
