import axios from 'axios'

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    submitForm({rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        for (const fieldName in payload.fields) {
          formData.append(fieldName, payload.fields[fieldName])
        }

        axios.post(
          `${process.env.VUE_APP_WP_HTTP}${process.env.VUE_APP_WP_URL}/wp-json/contact-form-7/v1/contact-forms/${payload.id}/feedback`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },

  modules: {
  }
}
