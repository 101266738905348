<template>
  <b-carousel
    icon-pack="far"
    :pause-info="false"
    :autoplay="false">
    <b-carousel-item v-for="(slide, i) in slides" :key="i">
      <section class="hero is-large" :style="`background: url(${slide.image}) center no-repeat;`">
        <div class="hero-body">
        </div>
      </section>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
export default {
  name: 'Slider',

  props: {
    slides: Array,
  }
}
</script>
