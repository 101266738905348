<template>
  <section class="cus-copyright-footer section pt-5 pb-5">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <p class="mb-4 has-text-weight-bold">{{ text }}</p>

          <a class="cus-partner-logo" :href="partner.url" target="_blank" v-for="(partner, partnerKey) in $store.getters['wp/acf'].partners" :key="partnerKey"
          :alt="`Klik om naar de website van de partner ${partner.name} te gaan.`">
            <img :src="partner.logo.url" :alt="`Logo van onze partner ${partner.name}.` ">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CopyrightFooter-Component',

  computed: {
    text() {
      return this.$store.getters['wp/acf'].subfooter_text ?? ''
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/components/protected/CopyrightFooter.scss"></style>
